import React, { useEffect, useState } from 'react'
// import { Link, graphql } from 'gatsby'
// import Layout from '../components/layout'
import AboutMe from '../components/aboutMe'
import Portfolio from '../components/portfolio'
import Skills from '../components/skills'
import WorkHistory from '../components/workHistory'
import ContactMe from '../components/contactMe'
// import Img from 'gatsby-image'
import SEO from '../components/seo'
import SocialMediaLinks from '../components/socialMediaLinks'

// import { FaInstagram, FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa'
// import { Parallax, ParallaxLayer } from 'react-spring/addons'
import addToMailchimp from 'gatsby-plugin-mailchimp'

const IndexPage = props => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [hover, setHover] = useState(false)
  const [deg, setDeg] = useState(0)

  const [result, setResult] = useState('')
  // useEffect(() => {
  //   setInterval(() => {
  //     setDeg(deg >= 83 ? deg + 1 : 83)
  //   }, 1000)
  // })
  const handleChange = e => {
    setResult('')
    setEmail(e.target.value)
  }
  const handleName = e => {
    setResult('')
    setName(e.target.value)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const result = await addToMailchimp(email, { FNAME: name })
    setResult(result)
    if (result.result === 'success') {
      setName('')
      setEmail('')
    }
    console.log('result', result)
  }
  return (
    <div
      className="landing"
      style={{
        width: '100%',
        height: '100vh',
        // background: `linear-gradient(250deg,#e5d2b5 4%, #ddb8d8 30%, #ffb755 ${deg}%, #e4a9ab 100%)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: '"Source Code Pro", monospace',
        // transition: 'background 5s ease 50s',
      }}
    >
      {/* <Layout location={props.location}> */}
      <SEO
        title="Home"
        keywords={[
          `habiba gadalla`,
          `coding codessa`,
          `react`,
          'codingcodessa',
          'habiba',
          'womenintech',
          'mentor',
          'coder',
          'code',
          'programmer',
          'حبيبة جادالله',
        ]}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          alignItems: 'center',
          width: '95vw',
          // textAlign: 'justify',
          maxWidth: '500px',
        }}
      >
        <h1 style={{ color: 'white', fontSize: '30px' }}>Coming Soon</h1>

        <p style={{ color: 'black', margin: '20px' }}>
          I will also start sending monthly newsletters soon (maybe even before
          the website launch).
          <br />
          it will mainly be about tech, things i learn, self development, and
          more!{' '}
        </p>
        <form
          onSubmit={handleSubmit}
          style={{ display: 'flex', flexDirection: 'column', width: '60%' }}
        >
          <input
            type="name"
            label="Your name"
            name="name"
            autoComplete="name"
            placeholder="Your Name"
            value={name}
            onChange={handleName}
            style={{
              margin: '4px 0',
              padding: '8px',
              border: 'none',
            }}
          />
          <input
            type="email"
            label="Your Email"
            placeholder="Your Email"
            name="email"
            value={email}
            autoComplete="email"
            onChange={handleChange}
            style={{
              margin: '4px 0',
              padding: '8px',
              border: 'none',
            }}
          />
          <button
            type="submit"
            onMouseEnter={() => {
              setHover(true)
            }}
            onMouseLeave={() => {
              setHover(false)
            }}
            style={{
              margin: '4px 0',
              background: hover ? '#ffb755' : '#e4a9ab',
              boxShadow: 'none',
              border: 'none',
              padding: '10px',
              color: 'white',
              fontWeight: 'bolder',
              cursor: 'pointer',
            }}
          >
            Add Me To Your List
          </button>
        </form>
        <span
          style={{
            textAlign: 'center',
            fontSize: '13px',
            margin: '10px',
            color: result.result === 'error' ? 'red' : 'white',
          }}
        >
          {result.msg}
        </span>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '20px',
        }}
      >
        <h6 style={{ color: 'black' }}>meanwhile, you can find me here</h6>

        <SocialMediaLinks size={28} />
      </div>
    </div>
  )
}

export default IndexPage
// export const pageQuery = graphql`
//   query {
//     avatar: file(relativePath: { eq: "avatar.jpg" }) {
//       childImageSharp {
//         fixed(width: 400) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
